import { clearStorage } from './clearStorage'

const localNameMap = {
  // 聚合管理
  aggregateConfig: 'aggregateData_1115',
  // 综合报表
  synthReport: 'synthData_1115',
  // 竞价广告配置
  RtbConfigReport: 'RTBData_1116',
  // 自动化运营
  AutoReport: 'AutoData_1007-1',
  // 直投广告
  // 广告计划
  adPlanStorageName: 'storageCustAdverPlan_0911',
  // 广告组
  adGroupStorageName: 'storageCustGroup_0911',
  // 广告素材
  adCreativeStorageName: 'storageCustAdverCreative_0911',
  // 媒体结算
  // 应用维度
  mediasettleAppLocalName: 'storageMediaSettleApp_0911',
  // 广告位维度
  mediasettleAdvLocalName: 'storageMediaSettleAdv_0911',
  // 广告平台维度
  mediasettlePlatLocalName: 'storageMediaSettlePlat_0911',
  // ADX消耗
  adxcost: 'adxcost_1211'

}

// 清除多余缓存
clearStorage('aggregateData_', localNameMap.aggregateConfig)
clearStorage('synthData_', localNameMap.synthReport)
clearStorage('RTBData_', localNameMap.RtbConfigReport)
clearStorage('AutoData_', localNameMap.AutoReport)

// 直客
clearStorage('storageCustAdverPlan_', localNameMap.adPlanStorageName)
clearStorage('storageCustGroup_', localNameMap.adGroupStorageName)
clearStorage('storageCustAdverCreative_', localNameMap.adCreativeStorageName)
// 媒体结算
clearStorage('storageMediaSettleApp', localNameMap.mediasettleAppLocalName)
clearStorage('storageMediaSettleAdv', localNameMap.mediasettleAdvLocalName)
clearStorage('storageMediaSettlePlat', localNameMap.mediasettlePlatLocalName)
// ADX消耗
clearStorage('adxcost_', localNameMap.adxcost)

export default localNameMap
