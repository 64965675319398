<template>
  <div class="table-container">
    <div class="table-head">
      <div class="head-left">
        <a-button type="primary" @click="handleAddSource"> <a-icon type="plus" />广告源 </a-button>
      </div>
      <div class="head-right">
        <a-button type="link" @click="handleClickCustTagsBtn"><a-icon type="edit" />自定义指标</a-button>
        <a-dropdown
          type="primary"
          class="dropdown"
          :disabled="downloading"
          :trigger="['click']"
        >
          <span><a-icon type="download" /> 导出</span>
          <a-menu slot="overlay" style="width: 120px" v-model="type" @click="clikMenu">
            <a-menu-item :key="0"> 广告源维度 </a-menu-item>
            <a-menu-item :key="1"> 流量广告位维度 </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <div class="table-content">
      <a-spin :spinning="isLoading">
        <el-table
          :key="tableKey"
          :data="tableData"
          style="width: 100%"
          :expand-row-keys="expandRowKeys"
          border
          :row-key="(row) => row.id"
          @sort-change="handleSortChange"
          @row-click="rowClick"
        >
          <!-- 子表 start -->
          <el-table-column width="1" type="expand">
            <template>
              <div class="inner-table-head" style="padding: 0 10px 10px 10px">
                <el-popover v-if="innerTableData.length" placement="bottom-start" :ref="'popover' + expandRowKeys[0]">
                  <div style="padding: 5px">
                    <a-radio style="font-weight: bold; color: #333" :checked="true">状态</a-radio>
                    <div style="display: flex; margin-top: 10px">
                      <div v-for="item of batchOption" :key="item.id">
                        <el-tooltip effect="dark" :disabled="selectList.length > 0" content="" placement="right-start">
                          <template v-if="!(+expandRowKeys[0] === 0 && [0, 1].includes(item.id))">
                            <el-button plain class="btnHover" @click="handleBatchAdd(item)">{{ item.name }}</el-button>
                          </template>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <a-button
                    slot="reference"
                    :disabled="selectList.length === 0"
                  >批量操作<a-icon
                    type="down"
                  /></a-button>
                </el-popover>
                <div class="adx-sts-box" v-if="currentExpandRow.type !== 'adx'">
                  <a-popconfirm
                    :title="`确认${currentExpandRow.adxSts === 'A' ? '关闭' : '开启'}Medaitom ADX消耗?`"
                    ok-text="是"
                    v-if="+currentExpandRow.authSts === 2"
                    cancel-text="否"
                    @confirm="changeAdxSts"
                  >
                    <span class="label">开启Medaitom ADX消耗</span>
                    <a-switch :checked="currentExpandRow.adxSts === 'A'"/>
                  </a-popconfirm>
                  <a-button type="link" v-if="+currentExpandRow.authSts === 2" @click="handleEditAdxSync"><a-icon type="edit" />编辑</a-button>
                </div>
              </div>
              <a-spin :spinning="innerIsLoading">
                <div style="width: 750px; padding: 25px 0" v-if="!innerTableData.length">
                  <m-empty imgHeight="120px" style="background: #f7f7ff">
                    <template #description> 未配置流量 </template>
                  </m-empty>
                </div>
                <el-table
                  v-else
                  :data="innerTableData"
                  style="width: 100%; border-radius: 5px; overflow: hidden"
                  border
                  fit
                  :row-key="(row) => row.placeId"
                  @sort-change="handleInnerSortChange"
                  @select-all="selectAll"
                  @selection-change="selectionChange"
                >
                  <el-table-column align="center" type="selection" width="55"> </el-table-column>
                  <el-table-column label="应用" prop="appName" width="160">
                    <template slot-scope="{ row }">
                      <a-popover placement="bottomLeft" trigger="hover">
                        <div style="width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                          <IosSvg v-if="+row.os === 0" class="svg-icon" />
                          <AndroidSvg v-else class="svg-icon" />
                          {{ row.appName }}
                        </div>
                        <div class="rta-info-box" slot="content">
                          <span>应用ID:</span>
                          <div>
                            <span class="value">{{ row.appId }}</span>
                            <span class="copy" @click="copyId(row.appId)">复制</span>
                          </div>
                        </div>
                      </a-popover>
                    </template>
                  </el-table-column>
                  <el-table-column label="广告位" prop="placeName" width="171">
                    <template slot-scope="{ row }">
                      <a-popover placement="bottomLeft" trigger="hover">
                        <div style="width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                          {{ row.placeName }}
                        </div>
                        <div class="rta-info-box" slot="content">
                          <span>广告位ID:</span>
                          <div>
                            <span class="value">{{ row.placeId }}</span>
                            <span class="copy" @click="copyId(row.placeId)">复制</span>
                          </div>
                        </div>
                      </a-popover>
                    </template>
                  </el-table-column>
                  <el-table-column label="广告样式" prop="positionName" width="100"> </el-table-column>
                  <el-table-column label="状态" prop="sts" width="60">
                    <template slot-scope="{ row }">
                      <a-switch
                        @click="handleChangeInnerSts($event, row)"
                        size="small"
                        :checked="row.sts === 'A'"
                      ></a-switch>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="180">
                    <div class="action-slot" slot-scope="{ row }">
                      <span class="btn" v-if="+expandRowKeys[0] === 0" @click="handleEditAdx(row)">编辑</span>
                      <span class="btn" v-if="+expandRowKeys[0] !== 0" @click="handleSetPlace(row)">编辑策略</span>
                      <span class="line" v-if="+expandRowKeys[0] !== 0">|</span>
                      <a-popconfirm
                        v-if="+expandRowKeys[0] !== 0"
                        title="确认移除配置?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="removeConfigured([row.placeId])"
                      >
                        <span class="btn">移除配置</span>
                      </a-popconfirm>
                      <a-popover v-if="+expandRowKeys[0] !== 0 && (+row.position === 1 || +row.position === 3 || +row.position === 5 || +row.position === 6 || (+row.position === 10 && +row.adStyle === 1))" :ref="'settingPopover' + row.placeId" trigger="hover" placement="right">
                        <span @click.stop style="padding: 0 3px; cursor: pointer">
                          <img
                            style="margin: 2px 3px 4px 3px; width: 3px; height: 13px"
                            src="@/assets/images/more.png"
                            alt=""
                          />
                        </span>
                        <div slot="content" class="dsp-more-setting">
                          <span
                            class="btn"
                            @click="handleSetAdxPlace(row)"
                          ><a-icon class="icon setting" type="setting" />配置广告交互策略</span
                          >
                        </div>
                      </a-popover>
                    </div>
                  </el-table-column>
                  <!-- 自定义指标 -->
                  <el-table-column
                    align="left"
                    v-for="item in sureArr"
                    :key="item.prop"
                    :width="item.width"
                    :prop="item.prop"
                    :sortable="item.sorter ? 'custom' : undefined"
                    :sort-orders="['descending', 'ascending', null]"
                  >
                    <template slot="header">
                      <m-tips :showIcon="false" :content="item.desc" :title="item.label" :width="150"></m-tips>
                    </template>
                    <template slot-scope="{ row }">
                      <!-- 响应时间 -->
                      <RowAdvResponseTime
                        v-if="item.prop === 'advResponseTime'"
                        :maxAdvResponseTime="+row.maxAdvResponseTime"
                        :advResponseTime="+row.advResponseTime"
                      ></RowAdvResponseTime>
                      <template v-else-if="+expandRowKeys[0] === 0 && item.prop === 'profitRate'">--</template>
                      <template v-else-if="+expandRowKeys[0] === 0 && item.prop === 'advCpc'">--</template>
                      <template v-else-if="isRota(item.prop)"> {{ row[item.prop] }}% </template>
                      <template v-else>
                        {{ numFormat(row[item.prop]) }}
                      </template>
                    </template>
                  </el-table-column>
                </el-table>
              </a-spin>
              <a-pagination
                class="pagination"
                v-if="innerTableData.length > 0"
                :current="innerquery.page"
                :total="innerTotal"
                show-size-changer
                :show-total="(innerTotal) => `共 ${innerTotal} 条`"
                @change="handleInnerChangePage"
                @showSizeChange="onShowInnerSizeChange"
                :pageSize="innerquery.limit"
              />
            </template>
          </el-table-column>
          <!-- 子表 end -->
          <el-table-column label="广告平台" prop="platName" :fixed="true" width="160"> </el-table-column>
          <el-table-column label="广告源名称" prop="name" :fixed="true" width="250">
            <template slot-scope="{ row }">
              <a-popover v-if="row.type !== 'sum' && row.type !== 'adx'" placement="bottom" trigger="hover">
                <template #content>
                  <div class="rtb-source-popover">
                    <div class="rtb-source-popover-item">
                      <span>账号：{{ row.accountName }}</span>
                      <a-button size="small" type="link" @click="copyId(row.accountName)">复制</a-button>
                    </div>
                    <div class="rtb-source-popover-item">
                      <span>广告源ID：{{ row.platPlaceId }}</span>
                      <a-button size="small" type="link" @click="copyId(row.platPlaceId)">复制</a-button>
                    </div>
                  </div>
                </template>
                <div
                  style="width: 230px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                  :title="row.name"
                >
                  {{ row.name ? row.name : '--' }}
                </div>
              </a-popover>
              <div v-else>{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="广告样式" prop="positionName" :fixed="true" width="80">
            <template slot-scope="{ row }">
              <span>{{ row.positionName ? row.positionName : '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="sts" width="60" :fixed="true">
            <template slot-scope="{ row }">
              <a-switch
                v-if="row.type !== 'sum'"
                @click.native.stop="handleChangeDspSts(row.sts === 'S', row.id)"
                size="small"
                :checked="row.sts === 'A'"
              ></a-switch>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" :fixed="true">
            <div slot-scope="{ row }" v-if="row.type !== 'sum'" class="action-slot">
              <span class="btn" @click.stop="handleExpandRow(row.id)">数据详情</span>
              <template v-if="row.type !== 'adx'">
                <span class="line">|</span>
                <span class="btn" @click.stop="handleConfigPlace(row)">流量配置</span>
              </template>
              <a-popover v-if="row.type !== 'adx'" :ref="'settingPopover' + row.id" trigger="hover" placement="right">
                <span @click.stop style="padding: 0 3px; cursor: pointer">
                  <img
                    style="margin: 2px 3px 4px 3px; width: 3px; height: 13px"
                    src="@/assets/images/more.png"
                    alt=""
                  />
                </span>
                <div slot="content" class="dsp-more-setting">
                  <span class="btn" @click="handleEditDsp(row)"><a-icon class="icon edit" type="edit" />编辑</span>
                  <span
                    class="btn"
                    @click="handleFrequency(row)"
                  ><a-icon class="icon setting" type="setting" />频次控制</span
                  >
                  <a-popconfirm
                    title="确认删除该广告源?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="handleDeleteDsp(row)"
                  >
                    <span class="more_setting">
                      <a-icon class="icon delete" type="delete" />
                      删除
                    </span>
                  </a-popconfirm>
                </div>
              </a-popover>
            </div>
            <span v-else>--</span>
          </el-table-column>
          <!-- 自定义指标 -->
          <el-table-column
            align="left"
            v-for="item in sureArr"
            :key="item.prop"
            :width="item.width"
            :prop="item.prop"
            :sortable="item.sorter ? 'custom' : undefined"
            :sort-orders="['descending', 'ascending', null]"
          >
            <template slot="header">
              <m-tips :showIcon="false" :content="item.desc" :title="item.label" :width="150"></m-tips>
            </template>
            <template slot-scope="{ row }">
              <!-- 响应时间 -->
              <RowAdvResponseTime
                v-if="item.prop === 'advResponseTime'"
                :maxAdvResponseTime="+row.maxAdvResponseTime"
                :advResponseTime="+row.advResponseTime"
              ></RowAdvResponseTime>
              <template v-else-if="isRota(item.prop)"> {{ row[item.prop] }}% </template>
              <template v-else>
                {{ numFormat(row[item.prop]) }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </a-spin>
      <a-pagination
        class="pagination"
        v-if="tableData.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
    </div>
    <!-- 自定义指标 -->
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :storageName="storageName"
      :defaultCheckedList="defaultCheckedList"
    />
    <!-- 广告源弹窗 -->
    <SourceModal
      :brandList="brandList"
      :installAppList="installAppList"
      v-if="addSourceVisible"
      :visible="addSourceVisible"
      :sourceInfo="isAdd === 1 ? undefined : sourceInfo"
      @modalCancel="addSourceVisible = false"
      :isAdd="isAdd"
      :query="sourcequery"
      :hideInfo="hideInfo"
      @changeSource="changeSource"
    />
    <!-- 广告源弹窗 -->
    <SetAdxPlaceModal
      :visible="setAdxPlaceVisible"
      :placeInfo="sourceInfo"
      @modalCancel="setAdxPlaceVisible = false"
      @changePlace="changeInnerPlace"
    />
    <!-- adx广告位 -->
    <AdxPlaceModal
      :brandList="brandList"
      :installAppList="installAppList"
      v-if="adxPlaceVisible"
      :visible="adxPlaceVisible"
      :sourceInfo="isAdd === 1 ? undefined : sourceInfo"
      @modalCancel="adxPlaceVisible = false"
      :isAdd="isAdd"
      :query="sourcequery"
      :hideInfo="hideInfo"
      @changeSource="changeInnerPlace"
    />
    <!-- 流量配置 -->
    <batchConfigPlaceModal
      :platInfo="platInfo"
      :platAccountInfo="platAccountInfo"
      :dspPlaceInfo="sourceInfo"
      :visible="configPlaceVisible"
      @modalCancel="configPlaceVisible = false"
      @configPlaceSuccess="configPlaceSuccess"
      @updateData="getAllAccountList"
    />
    <!-- 频次控制 -->
    <FrequencyModal
      v-if="frequencyVisible"
      :visible="frequencyVisible"
      :sourceInfo="sourceInfo"
      type="rtb"
      @modalCancel="frequencyVisible = false"
      @changeSource="changeSource"
      :query="query"
    />
    <!--  -->
    <adxSourceModal
      :positionId="+sourceInfo.position"
      v-if="adxSourceVisible"
      :visible="adxSourceVisible"
      :sourceInfo="sourceInfo"
      @modalCancel="adxSourceVisible = false"
      :isAdd="false"
      :query="{
        appId: sourceInfo.appId,
        placeId: sourceInfo.placeId
      }"
      @changeSource="changeInnerPlace"
    />
    <EditAdxSyncModal
      :visible="editAdxSyncVisible"
      :adxInfo="currentExpandRow"
      @modalCancel="editAdxSyncVisible = false"
      @editSuccess="editAdxSyncSuccess"
    />
    <!-- 批量操作删除提示弹窗 -->
    <a-modal
      dialogClass="delModal"
      wrapClassName="batchRemove"
      v-model="popconfirmVisible"
      title="提示"
      :confirm-loading="false"
      @ok="removeConfigured(selectList)"
    >
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p>确认移除配置？</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { copy } from '@/utils/string'
import custTagObj from './init'
import localDataName from '@/utils/localDataName'
import CustTagsModal from '@/components/common/CustTagsModal'
import RowAdvResponseTime from './components/RowAdvResponseTime'
import EditAdxSyncModal from './components/EditAdxSyncModal'
import FrequencyModal from '../../Config/components/frequencyModal'
import SourceModal from './components/sourceModal'
import AdxPlaceModal from './components/AdxPlaceModal'
import AdxSourceModal from '../../Config/components/adxSourceModal'
import SetAdxPlaceModal from './components/SetAdxPlaceModal'
import {
  getBrandList,
  getRtbPlatformList,
  getRtbPlatAccounts,
  deleteRtbItem,
  getDirectionalSettingAppList
} from '@/api/common'
import {
  dspSourceReport,
  reportDetails,
  updateDspSts,
  updateConfiguredSts,
  removeConfigured,
  batchChangePlaceSts,
  updateAdxSts
} from '@/api/aggregate'
import batchConfigPlaceModal from './components/batchConfigPlaceModal'
import { numFormat } from '@/utils/dealNumber'
import excel from '@/utils/excel'
import request from '@/utils/request'
export default {
  name: 'RtaConfigTable',
  components: {
    CustTagsModal,
    RowAdvResponseTime,
    SourceModal,
    batchConfigPlaceModal,
    FrequencyModal,
    AdxSourceModal,
    IosSvg,
    AndroidSvg,
    EditAdxSyncModal,
    AdxPlaceModal,
    SetAdxPlaceModal
  },
  data () {
    return {
      setAdxPlaceVisible: false,
      // adx广告源编辑
      adxSourceVisible: false,
      // 频次控制
      frequencyVisible: false,
      // 列表key
      tableKey: '',
      // 流量配置弹窗
      configPlaceVisible: false,
      // 平台信息
      platInfo: {},
      // 账户信息
      platAccountInfo: {},
      // 添加广告源
      addSourceVisible: false,
      // adx广告位
      adxPlaceVisible: false,
      sourcequery: {},
      // 是否添加模式
      isAdd: 1,
      // dsp广告源信息
      sourceInfo: {},
      // 选中广告源列表
      hideInfo: [],
      // 品牌列表
      brandList: [],
      // 安装列表
      installAppList: [],
      // 展开列id集合
      expandRowKeys: [],
      // 缓存名
      storageName: localDataName.RtbConfigReport, // 本地缓存key
      // 当前自定义指标
      sureArr: [],
      defaultCheckedList: [],
      // 总指标
      targetArr: [],
      // 自定义指标弹窗
      custTagsVisible: false,
      // 父表数据
      tableData: [],
      // 子表数据
      innerTableData: [],
      // 父表loading
      isLoading: false,
      // 子表loading
      innerIsLoading: false,
      // 父表参数
      query: {
        sortBy: undefined,
        sortOrder: 'desc',
        page: 1,
        limit: 10,
        platIdList: [],
        platAccountIdList: [],
        dateStart: undefined,
        dateEnd: undefined,
        name: ''
      },
      // 子表参数
      innerquery: {
        sortBy: undefined,
        sortOrder: undefined,
        page: 1,
        limit: 10,
        dateStart: undefined,
        dateEnd: undefined,
        dspId: undefined
      },
      // 父表数据总数
      total: 0,
      // 子表数据总数
      innerTotal: 0,
      selectList: [],
      batchOption: [
        { id: 0, name: '批量配置' },
        { id: 1, name: '批量移除' },
        { id: 2, name: '批量开启' },
        { id: 3, name: '批量关闭' }
      ],
      popconfirmVisible: false,
      // 当前展开的dsp广告源信息
      currentExpandRow: {},
      // 显示修改adx广告源策略
      editAdxSyncVisible: false,
      accountList: [],
      type: [0],
      downloading: false
    }
  },
  created () {
    this.initTargetArr()
    this.getBrandList()
    this.getDirectionalSettingAppList()
    this.getAllAccountList()
  },
  methods: {
    handleSetAdxPlace (row) {
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.setAdxPlaceVisible = true
      this.$refs['settingPopover' + row.placeId].$children[0].sVisible = false
    },
    // 报表数据导出
    exportToExcel (query) {
      return request.post('/rtbAd/downReport', query, {
        responseType: 'blob'
      })
    },
    async clikMenu ({ key }) {
      this.type = [key]
      this.downloading = true
      this.isLoading = true
      const codeList = {
        0: ['platName', 'accountName', 'name', 'platPlaceId', 'positionName', 'sts'],
        1: ['appName', 'placeName', 'positionName', 'sts', 'platName', 'accountName', 'name', 'platPlaceId']
      }
      const nameList = {
        0: ['广告平台', '账户名称', '广告源名称', '广告源ID', '广告样式', '状态'],
        1: ['应用', '广告位', '广告样式', '状态', '广告平台', '广告平台账户', '广告源名称', '广告源ID']
      }
      const reportName = {
        0: '广告源维度',
        1: '流量广告源维度'
      }
      const codeArr = []
      const nameArr = []
      this.sureArr.forEach((item) => {
        codeArr.push(item.prop)
        nameArr.push(item.label)
      })
      const { dateStart = '', dateEnd = '', platIdList = [], platAccountIdList = [] } = this.query
      try {
        const res = await this.exportToExcel({
          dateStart,
          dateEnd,
          platIdList,
          platAccountIdList,
          type: key,
          codeList: [ ...codeList[key], ...codeArr ],
          nameList: [ ...nameList[key], ...nameArr ]
        })
        await excel(res, `${reportName[key]}.xlsx`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
      this.isLoading = false
      this.downloading = false
    },
    editAdxSyncSuccess (query) {
      const curAdx = this.tableData.find((item) => item.id === this.currentExpandRow.id)
      curAdx.settleStrategy = query.settleStrategy
      curAdx.settleValue = query.settleValue
      this.currentExpandRow.settleStrategy = query.settleStrategy
      this.currentExpandRow.settleValue = query.settleValue
    },
    handleEditAdxSync () {
      this.editAdxSyncVisible = true
    },
    changeAdxSts () {
      if (+this.currentExpandRow.authSts !== 2) {
        return this.$message.warning('请先授权广告平台账号')
      }
      const e = !(this.currentExpandRow.adxSts === 'A')
      updateAdxSts({
        ...this.currentExpandRow,
        adxSts: e ? 'A' : 'S'
      }).then((res) => {
        if (res.code === 200) {
          const curAdx = this.tableData.find((item) => item.id === this.currentExpandRow.id)
          curAdx.adxSts = e ? 'A' : 'S'
          this.currentExpandRow.adxSts = e ? 'A' : 'S'
          this.$message.success(`${e ? '开启' : '关闭'}成功！`)
        }
      })
    },
    // 行点击
    rowClick (row, column, event) {
      if (row.type === 'sum') return
      this.handleExpandRow(row.id)
    },
    numFormat,
    // 复制
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // dsp删除
    async handleDeleteDsp (row) {
      const resp = await deleteRtbItem({
        id: row.id
      })
      if (resp.code === 200) {
        this.$message.success('删除成功！')
        this.changeSource()
      }
    },
    // 是否是百分比
    isRota (prop) {
      const rotaPropList = [
        'advRequestFilledRate',
        'impressRate',
        'clickRate',
        'askPriceFilledRate',
        'bidSuccessRate',
        'impressRequestRate',
        'clickGap',
        'impressionGap',
        'profitRate'
      ]
      return rotaPropList.includes(prop)
    },
    // 点击修改广告位
    handleEditAdx (row) {
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.sourceInfo.id = this.sourceInfo.sourceId
      this.adxSourceVisible = true
    },
    // 点击配置广告位
    handleSetPlace (row) {
      this.sourcequery = {
        ...this.innerquery
      }
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.sourceInfo.placeIdList = [row.placeId]
      this.isAdd = 5
      this.adxPlaceVisible = true
    },
    // 移除配置接口
    async removeConfigured (placeIdList) {
      const query = {
        id: this.expandRowKeys[0],
        placeIdList
      }
      await removeConfigured(query)
      this.$message.success('修改成功')
      this.selectList = [] // 多选清空
      this.popconfirmVisible = false
      this.getInnerData()
    },
    // 批量修改状态
    batchChangeSts (sts) {
      batchChangePlaceSts({
        sts,
        placeIdList: this.selectList,
        id: this.expandRowKeys[0]
      }).then(async (res) => {
        this.$message.success('修改成功')
        this.selectList = [] // 多选清空
        await this.getTableList()
        await this.getInnerData()
      })
    },
    // 批量配置
    handleBatchAdd (data) {
      // data.id  ===  0-批量配置，1: 批量移除 2: 批量开启 3: 批量关闭
      if (data.id === 1) {
        this.popconfirmVisible = true
      } else if (data.id === 0) {
        this.sourceInfo = this.innerTableData.find((item) => item.placeId === this.selectList[0])
        this.sourceInfo.placeIdList = this.selectList
        this.sourceInfo.accessType = this.hideInfo.every((item) => +item.accessType === 0) ? 0 : 1
        this.isAdd = 4
        this.sourcequery = {
          ...this.innerquery
        }
        this.adxPlaceVisible = true
      } else if (data.id === 2) {
        this.batchChangeSts('A')
      } else if (data.id === 3) {
        this.batchChangeSts('S')
      }
      this.$refs['popover' + this.expandRowKeys[0]].showPopper = false
    },
    // 修改选中dsp广告源
    selectionChange (selection) {
      this.hideInfo = selection
      this.selectList = selection.map((item) => item.placeId)
    },
    // 选择全部dsp广告源
    selectAll (selection) {
      this.hideInfo = selection
      this.selectList = selection.map((item) => item.placeId)
    },
    // 修改子表状态
    handleChangeInnerSts (e, row) {
      this.innerIsLoading = true
      updateConfiguredSts({
        sts: e ? 'A' : 'S',
        id: row.id,
        placeIdList: [row.placeId]
      }).then(async () => {
        row.sts = e ? 'A' : 'S'
        this.$message.success('操作成功！')
        this.innerIsLoading = false
        // if (e) {
        //   const dsp = this.tableData.find((item) => +item.id === +row.id)
        //   dsp.sts = 'A'
        // }
        await this.getTableList()
        await this.getInnerData()
      })
    },
    // 修改父表状态
    async handleChangeDspSts (e, id) {
      updateDspSts({
        id,
        sts: e ? 'A' : 'S'
      }).then(() => {
        this.$message.success('操作成功！')
        this.expandRowKeys = []
        this.getTableList()
      })
    },
    async getAllAccountList () {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: []
      })
      this.accountList = data
    },
    // 获取账户信息
    async getAccountInfo (platId, accountId) {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: [platId]
      })
      this.platAccountInfo = data.find((item) => +item.id === +accountId)
    },
    // 获取广告平台信息
    async getPlatInfo (id) {
      const { data = [] } = await getRtbPlatformList()
      this.platInfo = data.find((item) => +item.id === +id)
    },
    // 点击修改dsp广告源
    handleEditDsp (row) {
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.addSourceVisible = true
      this.isAdd = 2
      this.$refs['settingPopover' + row.id].$children[0].sVisible = false
    },
    // dsp广告源频次控制
    handleFrequency (row) {
      this.frequencyVisible = true
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.$refs['settingPopover' + row.id].$children[0].sVisible = false
    },
    // 打开流量配置弹窗
    handleConfigPlace (row) {
      const resp = this.getPlatInfo(row.platId)
      const res = this.getAccountInfo(row.platId, row.platAccountId)
      Promise.all([resp, res]).then(() => {
        this.sourceInfo = JSON.parse(JSON.stringify(row))
        this.configPlaceVisible = true
      })
    },
    // 流量配置弹窗成功回调
    configPlaceSuccess () {
      this.configPlaceVisible = false
      this.expandRowKeys = []
      this.getTableList()
    },
    // 修改父表page
    handleChangePage (page) {
      this.query.page = page
      this.getTableList()
    },
    // 修改父表limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableList()
    },
    // 修改子表page
    handleInnerChangePage (page) {
      this.innerquery.page = page
      this.getInnerData()
    },
    // 修改子表limit
    onShowInnerSizeChange (current, size) {
      this.innerquery.page = 1
      this.innerquery.limit = size
      this.getInnerData()
    },
    // 获取子表数据
    async getInnerData () {
      this.innerIsLoading = true
      this.selectList = []
      const { data = [] } = await reportDetails(this.innerquery)
      const { total = 0, items = [] } = data
      this.innerTableData = items
      this.innerTotal = total
      this.innerIsLoading = false
    },
    // 获取父表数据
    async getTableList () {
      this.selectList = []
      // this.expandRowKeys = []
      this.isLoading = true
      const { data = [] } = await dspSourceReport(this.query)
      const { total = 0, items = [], aggregate = {} } = data
      aggregate.type = 'sum'
      aggregate.id = 'sum'
      aggregate.platName = '汇总'
      this.total = total
      if (items.length > 0) {
        const adx = items.find((child) => +child.id === 0)
        adx && (adx.type = 'adx')
      }
      this.tableData = [aggregate, ...items]
      this.isLoading = false
    },
    // dsp广告源弹窗成功回调
    changeSource () {
      // this.query.sortBy = undefined
      this.query.page = 1
      this.getTableList()
    },
    // 子表修改事件
    changeInnerPlace () {
      // this.innerquery.sortBy = undefined
      this.innerquery.page = 1
      this.getInnerData()
    },
    // 添加dsp广告源
    handleAddSource () {
      this.isAdd = 1
      this.sourcequery = {
        ...this.query
      }
      this.addSourceVisible = true
    },
    // 安装列表
    async getDirectionalSettingAppList () {
      const { data = [] } = await getDirectionalSettingAppList()
      this.installAppList = data
    },
    // 获取品牌
    async getBrandList () {
      const { data = [] } = await getBrandList()
      data.forEach((item) => {
        item.id = item.name
      })
      this.brandList = Object.freeze(data)
    },
    // 供父组件调用
    async handleSearch (query) {
      this.query = {
        ...query,
        limit: this.query.limit,
        dspId: query.dspId || undefined
      }
      this.expandRowKeys = []
      this.query.sortBy = undefined
      this.query.page = 1
      await this.getTableList()
      if (query.dspId) {
        const dsp = this.tableData.find((item) => +item.id === +query.dspId)
        this.handleConfigPlace(dsp)
      }
    },
    // 父表排序
    handleSortChange ({ column, prop, order }) {
      if (order === 'ascending') {
        this.query.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.query.sortOrder = 'desc'
      } else {
        this.query.sortOrder = undefined
      }
      this.query.sortBy = prop
      this.query.page = 1
      this.getTableList()
    },
    // 打开自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 自定义指标弹窗成功回调
    changeCustTags (targetArr, sureArr) {
      this.tableKey = '' + new Date().getTime()
      this.targetArr = targetArr
      this.sureArr = sureArr
    },
    // 子表排序
    handleInnerSortChange ({ column, prop, order }) {
      if (order === 'ascending') {
        this.innerquery.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.innerquery.sortOrder = 'desc'
      } else {
        this.innerquery.sortOrder = undefined
      }
      this.innerquery.sortBy = prop
      this.getInnerData()
    },
    getAuthSts (id) {
      if (!id) {
        return
      }
      return this.accountList.find((item) => +item.id === +id).authSts
    },
    // 打开折叠
    handleExpandRow (id) {
      if (this.expandRowKeys.length && this.expandRowKeys[0] === id) {
        this.expandRowKeys = []
      } else {
        this.currentExpandRow = JSON.parse(JSON.stringify(this.tableData.find((item) => +item.id === +id)))
        // 获取授权状态
        this.currentExpandRow.authSts = this.getAuthSts(this.currentExpandRow.platAccountId)
        this.expandRowKeys = [id]
        this.innerquery.page = 1
        this.innerquery.limit = 10
        this.innerquery.dspId = id
        this.innerquery.sortBy = undefined
        this.innerquery.sortOrder = undefined
        this.innerquery.dateStart = this.query.dateStart
        this.innerquery.dateEnd = this.query.dateEnd
        this.getInnerData()
      }
    },
    // 初始化自定义指标
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = custTagObj
      this.defaultCheckedList = JSON.parse(JSON.stringify(sureArr))
      this.targetArr = JSON.parse(JSON.stringify(targetArr))
      if (!tagInfo) {
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
    }
  }
}
</script>

<style lang="less" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  .table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .head-right {
      display: flex;
      align-items: center;
      cursor: pointer;
      .dropdown {
        color: #3366ff;
      }
    }
  }
  .table-content {
    .inner-table-head{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .adx-sts-box{
        padding-left: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .label{
          font-size: 12px;
          margin: 0 5px;
        }
      }
    }
    .svg-icon{
      height: 13px;
      width: 13px;
    }
    .icon {
      cursor: pointer;
      color: @primary-color;
      margin-left: 10px;
    }
    .action-slot {
      user-select: none;
      .btn {
        color: @primary-color;
        cursor: pointer;
      }
      .line {
        color: #ccc;
      }
      span {
        margin-right: 5px;
      }
    }
    .pagination {
      padding: 15px 10px;
    }
  }
}
::v-deep {
  .btnHover {
    border: none;
    box-shadow: none;
    &:hover {
      color: @primary-color;
      font-weight: 600;
    }
  }
}
::v-deep.table-container {
  .el-table__expand-column.el-table__cell {
    display: inline-block;
    width: 0;
    border: none;
    overflow: hidden;
  }
}
@backColor: #f7f7ff;
::v-deep.table-container .el-table__cell.el-table__expanded-cell {
  background-color: @backColor!important;
  tr,
  th,
  td,
  .el-table__empty-text,
  .el-table__empty-block {
    background-color: @backColor!important;
  }
}
::v-deep {
  .batchRemove {
    // top: 22%;
    .ant-modal-body {
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      border-top: none;
      padding: 14px;
    }
    .ant-btn,
    .ant-btn-primary {
      width: 90px;
    }
  }
}
.dsp-more-setting {
  display: flex;
  flex-direction: column;
  & > span {
    cursor: pointer;
  }
  .icon {
    margin: 0 5px 0 0;
    &.delete {
      color: red;
    }
    &.edit {
      color: #fa8b15;
    }
    &.setting {
      color: springgreen;
    }
  }
}
</style>

<style lang="less">
.rta-info-box {
  display: flex;
  justify-content: space-between;
  width: 250px;
  .copy {
    color: @primary-color;
    cursor: pointer;
    padding: 0 5px;
  }
}
.rtb-source-popover {
  width: 240px;
  .rtb-source-popover-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      // max-width: 240px;
      line-height: 30px;
    }
  }
}

</style>
